// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.users-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-rows: 300px;
    margin: 15px 15px;
    padding: 40px 0;
    background-color: #e9f1fb;
    justify-content: center;
}

.people-instruction{
    margin: 25px 20px 0 20px;
    padding: 15px;
    background-color: #eef2f7; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
    font-size: 1.1em;
    line-height: 1.4;
}


@media screen and (max-width: 768px) {

    .users-container{
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        grid-auto-rows: 80px;
        margin: 10px;
        width: 100%;
        padding-top: 50px;
        justify-content: center;
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/UserCards.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,+CAA+C;IAC/C,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;;AAGA;;IAEI;QACI,aAAa;QACb,+CAA+C;QAC/C,oBAAoB;QACpB,YAAY;QACZ,WAAW;QACX,iBAAiB;QACjB,uBAAuB;IAC3B;;AAEJ","sourcesContent":["\n.users-container{\n    display: grid;\n    grid-template-columns: repeat(auto-fill, 200px);\n    grid-auto-rows: 300px;\n    margin: 15px 15px;\n    padding: 40px 0;\n    background-color: #e9f1fb;\n    justify-content: center;\n}\n\n.people-instruction{\n    margin: 25px 20px 0 20px;\n    padding: 15px;\n    background-color: #eef2f7; \n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n    text-align: center;\n    font-size: 1.1em;\n    line-height: 1.4;\n}\n\n\n@media screen and (max-width: 768px) {\n\n    .users-container{\n        display: grid;\n        grid-template-columns: repeat(auto-fill, 250px);\n        grid-auto-rows: 80px;\n        margin: 10px;\n        width: 100%;\n        padding-top: 50px;\n        justify-content: center;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
