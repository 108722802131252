export const structure = `
Topic Title;
Study Guide Level;
Brief Overview;
Detailed Subtopics;
Learning Objectives;
Study Techniques and Resources;
Weekly Study Plan;
Daily Study Sessions;
Total Study Period and Average Daily Hours;
Consultation and Guidance;
Warnings and Precautions;
`

// Structure Example
export const structureExample = `
# Subject and Study Guide Level
Environmental Science
Beginner to Intermediate

;

# Introduction to the Topic
An interdisciplinary study focusing on the interaction between humans and the environment.

;

# Specific Areas of Study with Examples
* Climate Change - Greenhouse gases, global warming, impact on ecosystems.
* Ecosystems - Biodiversity, food webs, ecological balance.
* Pollution - Air, water, soil pollution, and control measures.
* Conservation - Sustainable practices, renewable resources, wildlife protection.

;

# Methods and Resources for Effective Studying
* Reading Scientific Papers and Reports - For in-depth understanding of current environmental issues.
* Participatory Learning - Engaging in community environmental projects or online forums.
* Documentaries and Virtual Tours - To visualize and understand real-world environmental scenarios.
* Experimentation and Projects - Conducting small-scale experiments or projects related to environmental science.

;

# Structured Schedule for Studying Different Topics
* Weekly Study Plan:
  - Week 1-2: Climate Change
  - Week 3-4: Ecosystems
  - Week 5-6: Pollution
  - Week 7-8: Conservation and Review

;

# Organizing Daily Study Activities for Balanced Learning
* Daily Study Sessions:
  - 1 hour: Reading/Research
  - 1 hour: Interactive Learning (Experiments, Documentaries)
  - 1 hour: Review and Practice (Quizzes, Discussions)

;

# Study Period
Total Study Period and Average Daily Hours: 8 weeks, 3 hours daily

;

# Consultation and Guidance
For complex topics or further clarification, consult with a qualified environmental science expert. Opportunities include online consultations, attending workshops, or seeking guidance from academic advisors.

;

# Warnings and Precautions
Be aware of the sensitivity of environmental topics. Ensure the ethical conduct of experiments and adhere to safety guidelines. Stay informed about the impact of environmental issues on diverse communities and avoid spreading misinformation.

;

`
