// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-page {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  max-height: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.singup-form{
  display: flex;
  flex-direction: column;
}
.signup-page h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Open Sans', sans-serif;
}

.signup-page input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.signup-page button {
  background-color: #00029d;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.signup-page button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/SignUp.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".signup-page {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  max-height: 600px;\n  margin: 50px auto;\n  padding: 20px;\n  background-color: #f7f7f7;\n  box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n  border-radius: 5px;\n}\n\n.singup-form{\n  display: flex;\n  flex-direction: column;\n}\n.signup-page h2 {\n  text-align: center;\n  margin-bottom: 20px;\n  color: #333;\n  font-family: 'Open Sans', sans-serif;\n}\n\n.signup-page input {\n  margin-bottom: 15px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-family: 'Roboto', sans-serif;\n}\n\n.signup-page button {\n  background-color: #00029d;\n  color: white;\n  padding: 12px;\n  border: none;\n  border-radius: 4px;\n  transition: background-color 0.3s ease;\n  font-size: 16px;\n}\n\n.signup-page button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
