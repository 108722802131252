// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message-container {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 100%;
    height: 100%;
    background-color: #e9f1fb;
}

.messages-content{
    padding: 0 20px;
    overflow: scroll;
}

.message-container {
    display: flex;
    height: 100vh;
  }
  
  .recipients-list {
    width: 20%;
    background-color: #e9f1fb; 
    overflow-y: auto; 
    padding: 10px;
  }
  
  .recipient-button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: none;
    cursor: pointer;
    background-color: #77b7fd;
}

.recipient-button:hover {
      background-color: #00029d;
      color: white;
      font-weight: bold;
  }
  
  .messages-content {
    flex-grow: 1;
    padding: 10px;
    background-color: #bbdeff; 
    overflow-y: auto; 
  }
  
  .message-user {
    font-weight: bold;
  }

  .message-user span{
    font-weight: lighter;
  }
  
  .message {
    margin-bottom: 10px;
  }
    




`, "",{"version":3,"sources":["webpack://./src/Components/MessagePage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sCAAsC;IACtC,wBAAwB;IACxB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B;;AAEA;MACM,yBAAyB;MACzB,YAAY;MACZ,iBAAiB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["\n.message-container {\n    display: grid;\n    grid-template-columns: min-content 1fr;\n    grid-template-rows: 100%;\n    height: 100%;\n    background-color: #e9f1fb;\n}\n\n.messages-content{\n    padding: 0 20px;\n    overflow: scroll;\n}\n\n.message-container {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .recipients-list {\n    width: 20%;\n    background-color: #e9f1fb; \n    overflow-y: auto; \n    padding: 10px;\n  }\n  \n  .recipient-button {\n    width: 100%;\n    padding: 10px;\n    margin: 5px 0;\n    border: none;\n    cursor: pointer;\n    background-color: #77b7fd;\n}\n\n.recipient-button:hover {\n      background-color: #00029d;\n      color: white;\n      font-weight: bold;\n  }\n  \n  .messages-content {\n    flex-grow: 1;\n    padding: 10px;\n    background-color: #bbdeff; \n    overflow-y: auto; \n  }\n  \n  .message-user {\n    font-weight: bold;\n  }\n\n  .message-user span{\n    font-weight: lighter;\n  }\n  \n  .message {\n    margin-bottom: 10px;\n  }\n    \n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
