// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.people-container {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 100%;
  height: 100%;
  background-color: #e9f1fb;
}


.users-container {
  background-color: #e9f1fb;
  overflow-y: auto; 
  height: 100vh;
}

@media screen and (max-width: 500px) {
  .people-container {
      height: 100vh;
  }
  /* will update hamburger still once I figure it out. */
}
  
  
`, "",{"version":3,"sources":["webpack://./src/Pages/People.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sCAAsC;EACtC,wBAAwB;EACxB,YAAY;EACZ,yBAAyB;AAC3B;;;AAGA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE;MACI,aAAa;EACjB;EACA,sDAAsD;AACxD","sourcesContent":["\n.people-container {\n  display: grid;\n  grid-template-columns: min-content 1fr;\n  grid-template-rows: 100%;\n  height: 100%;\n  background-color: #e9f1fb;\n}\n\n\n.users-container {\n  background-color: #e9f1fb;\n  overflow-y: auto; \n  height: 100vh;\n}\n\n@media screen and (max-width: 500px) {\n  .people-container {\n      height: 100vh;\n  }\n  /* will update hamburger still once I figure it out. */\n}\n  \n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
