// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 200px;
    margin: 10px;
    
}

.user-image{  
    border-radius: 10px;
    width: 180px;
    height: 200px;
    object-fit: cover;
}


.chat-icon {
    font-size: 24px; 
}

.chat-icon:hover {
    cursor: pointer; 
}

.user-info-below-image{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    object-fit: cover;
}

.user-info-first-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}

.user-info-first-line p {
    margin: 0; 
}

.user-info-second-line{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {



    .user-card {
        display: flex;
        flex-direction: row;
        width: 50px; 
        height: 50px; 
        gap: 10px;
    }

    .user-info-second-line{
        display: none;
    }

    .user-info-first-line{
        width: 200px;
    }


    .user-image{  
        border-radius: 10px;
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/UserCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;;AAEhB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;;;;IAII;QACI,aAAa;QACb,mBAAmB;QACnB,WAAW;QACX,YAAY;QACZ,SAAS;IACb;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;IAChB;;;IAGA;QACI,mBAAmB;QACnB,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".user-card{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 180px;\n    height: 200px;\n    margin: 10px;\n    \n}\n\n.user-image{  \n    border-radius: 10px;\n    width: 180px;\n    height: 200px;\n    object-fit: cover;\n}\n\n\n.chat-icon {\n    font-size: 24px; \n}\n\n.chat-icon:hover {\n    cursor: pointer; \n}\n\n.user-info-below-image{\n    margin: 0;\n    padding: 0;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    object-fit: cover;\n}\n\n.user-info-first-line {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 0px;\n}\n\n.user-info-first-line p {\n    margin: 0; \n}\n\n.user-info-second-line{\n    display: flex;\n    justify-content: space-between;\n}\n\n@media screen and (max-width: 768px) {\n\n\n\n    .user-card {\n        display: flex;\n        flex-direction: row;\n        width: 50px; \n        height: 50px; \n        gap: 10px;\n    }\n\n    .user-info-second-line{\n        display: none;\n    }\n\n    .user-info-first-line{\n        width: 200px;\n    }\n\n\n    .user-image{  \n        border-radius: 10px;\n        width: 50px;\n        height: 50px;\n        object-fit: cover;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
