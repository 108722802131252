// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.user-info-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.user-info-form label {
    display: block;
    margin-bottom: 10px;
}

.user-info-form input[type="text"],
.user-info-form input[type="number"],
.user-info-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.user-info-form textarea {
    height: 100px;
    resize: vertical;
}

.user-info-form fieldset {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 20px;
}

.user-info-form legend {
    padding: 0 10px;
    font-size: 1.1em;
    font-weight: bold;
}

.user-info-form button {
    padding: 10px 15px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.user-info-form button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserInfoForm.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;;;IAGI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.user-info-form {\n    max-width: 600px;\n    margin: 20px auto;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n}\n\n.user-info-form label {\n    display: block;\n    margin-bottom: 10px;\n}\n\n.user-info-form input[type=\"text\"],\n.user-info-form input[type=\"number\"],\n.user-info-form textarea {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.user-info-form textarea {\n    height: 100px;\n    resize: vertical;\n}\n\n.user-info-form fieldset {\n    border: 1px solid #ddd;\n    padding: 10px;\n    margin-top: 20px;\n}\n\n.user-info-form legend {\n    padding: 0 10px;\n    font-size: 1.1em;\n    font-weight: bold;\n}\n\n.user-info-form button {\n    padding: 10px 15px;\n    color: white;\n    background-color: #007bff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 20px;\n}\n\n.user-info-form button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
