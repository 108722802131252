// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  /* font-family: sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.products-container {
  display: flex;
  justify-content: center;
}

.products {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px; 
  border: 1px solid green;
}

.product {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  width: 300px;
  background-color: antiquewhite;
}

.index{
  display: flex;
}



img {
  /* width: 300px; */
  height: 200px; 
  object-fit: cover;
}



.bold {
  font-weight: bold;
}

.userpage-aside{
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,6BAA6B;EAC7B,mCAAmC;EACnC,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;;;GAGG;;;AAGH;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,2BAA2B;EAC3B,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;;;AAIA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB;;;;AAIA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["body {\n  margin: 0;\n  /* font-family: sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  padding-top: 60px;\n}\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n\n\n.products-container {\n  display: flex;\n  justify-content: center;\n}\n\n.products {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 0px; \n  border: 1px solid green;\n}\n\n.product {\n  border: 1px solid black;\n  padding: 10px;\n  margin: 10px;\n  width: 300px;\n  background-color: antiquewhite;\n}\n\n.index{\n  display: flex;\n}\n\n\n\nimg {\n  /* width: 300px; */\n  height: 200px; \n  object-fit: cover;\n}\n\n\n\n.bold {\n  font-weight: bold;\n}\n\n.userpage-aside{\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
