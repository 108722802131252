// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen-sharing-container {
    background-color: white;
    border: 1px solid blue;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 1.5%;
    top: 2.5%;
    height: 88%;
    width: 76%;
    overflow: hidden;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: 0 2px 4px blue;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.5); */


}

.remote-user-Screen{
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/ScreenSharing.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,SAAS;IACT,WAAW;IACX,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,0BAA0B;IAC1B,2CAA2C;;;AAG/C;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".screen-sharing-container {\n    background-color: white;\n    border: 1px solid blue;\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    left: 1.5%;\n    top: 2.5%;\n    height: 88%;\n    width: 76%;\n    overflow: hidden;\n    border-radius: 5px;\n    object-fit: cover;\n    box-shadow: 0 2px 4px blue;\n    /* box-shadow: 0 2px 4px rgba(0,0,0,0.5); */\n\n\n}\n\n.remote-user-Screen{\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
